import train5 from "../../../img/train5.png";
import queueNextSlide from "../../Util/queueNextSlide";
import { useState } from "react";
import BackgroundImage from "../../StyledComponents/BackgroundImage";
import Fade from "../../Transitions/Fade";
import SlideContainer from "../../StyledComponents/SlideContainer";
import BottomTextBox from "../../StyledComponents/BottomTextBox";

function Train5({ handleSlide, slide }) {
	const [fade, setFade] = useState("fadein");
	const delay = 700;
	const clickHandler = () => {
		queueNextSlide(handleSlide, slide, "ElevenAM");
		setFade("fadeout");
	};
	return (
		<Fade fadeProp={fade} durration="700ms">
			<SlideContainer onClick={clickHandler}>
				<BackgroundImage image={train5} />
				{/* <Fade fadeProp="fadein" durration="1400ms">
					<BottomTextBox opacity={0.98}>
						A homeless man sleeping across from you hears this, wakes up, and
						asks if you wouldn’t mind sharing some.
					</BottomTextBox>
				</Fade> */}
			</SlideContainer>
		</Fade>
	);
}
export default Train5;
