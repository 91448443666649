import SlideContainer from "../../StyledComponents/SlideContainer";
import BackgroundImage from "../../StyledComponents/BackgroundImage";
import chips from "../../../img/chips.png";
import styled from "styled-components";
import Fade from "../../Transitions/Fade";
import { useState } from "react";
import queueNextSlide from "../../Util/queueNextSlide";
const Text = styled.div`
	/* You open a bag of chips ... */

	position: absolute;
	width: 900px;
	height: 35px;
	left: 252px;
	top: 301px;

	font-family: "adobe_naskhmedium";
	font-style: normal;
	font-weight: 500;
	font-size: 63px;
	line-height: 34px;
	/* identical to box height, or 54% */

	text-align: center;

	color: #21622b;
`;
function Chips({ handleSlide, slide }) {
	const delay = 700;
	const [fade, setFade] = useState("fadein");
	const handleClick = () => {
		queueNextSlide(handleSlide, slide, "Train3", delay);

		setFade("fadeout");
	};
	return (
		<Fade fadeProp={fade} durration="700ms">
			<SlideContainer onClick={handleClick}>
				<BackgroundImage image={chips} />
				<Fade fadeProp="fadein" durration="1500ms">
					<Text>You open a bag of chips ...</Text>
				</Fade>
			</SlideContainer>
		</Fade>
	);
}

export default Chips;
