import { useState } from "react";
import queueNextSlide from "../../Util/queueNextSlide";
import SlideContiner from "../../StyledComponents/SlideContainer";
import BackgroundImage from "../../StyledComponents/BackgroundImage";
import Fade from "../../Transitions/Fade";
import Border from "../../StyledComponents/Border";
import toLowOpacityAnimation from "../../Transitions/toLowOpacityAnimation";
import toLowOpacityInvertAnimation from "../../Transitions/toLowOpacityInvertAnimation";
import reverseInvertAnimation from "../../Transitions/reverseInvertAnimation";
import BottomTextBox from "../../StyledComponents/BottomTextBox";
import shareChips from "../../../img/shareChips.png";
import styled from "styled-components";
const HeaderText = styled.div`
	position: absolute;
	width: 655px;
	height: 64px;
	left: 22px;
	top: 160px;

	font-family: "adobe_naskhmedium";
	font-style: normal;
	font-weight: 500;
	font-size: 44px;
	line-height: 34px;
	/* or 78% */

	text-align: center;
	/* text-decoration-line: underline; */

	color: #000000;
`;
const TopBox = styled.button`
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: absolute;
	width: 461px;
	height: 116px;
	left: 119px;
	top: 255px;
	cursor: pointer;
	border: 6.81px solid #5c5c5c;
	background-color: white;
`;
const BottomBox = styled.button`
	box-sizing: border-box;

	position: absolute;
	width: 461px;
	height: 116px;
	left: 119px;
	top: 428px;

	border: 6.81px solid #5c5c5c;
	display: flex;
	flex-direction: column;
	justify-content: center;
	cursor: pointer;
	background-color: white;
`;

const ChoiceText = styled.div`
	/* position: absolute; */
	width: 381px;
	height: 59px;
	/* left: 1393px; */
	/* top: 406px; */

	margin: 0 auto;
	font-family: "adobe_arabicregular";
	font-style: normal;
	font-weight: 400;
	font-size: 54px;
	line-height: 59px;

	color: black;
`;

const ShareChips = ({ handleSlide, slide }) => {
	const INITIAL = "initial";
	const SECOND_TEXTBOX = "second textbox";
	const MULTIPLE_CHOICE = "multiple choice";
	const SINGLE_CHOICE = "single choice";
	const WRONG_CHOICE = "wrong choice";
	const RIGHT_CHOICE = "right choice";
	const delay = 700;

	const [fade, setFade] = useState("fadein");
	const [state, setState] = useState(INITIAL);

	const handleClick = () => {
		if (state === INITIAL) {
			setState(SECOND_TEXTBOX);
		} else if (state === SECOND_TEXTBOX) {
			setState(MULTIPLE_CHOICE);
		} else if (state === MULTIPLE_CHOICE) {
			console.log("click a choice");
		} else if (state === WRONG_CHOICE) {
			setState(SINGLE_CHOICE);
		} else if (state === RIGHT_CHOICE) {
			queueNextSlide(handleSlide, slide, "Train4", delay);
			setFade("fadeout");
		}
	};
	const handleTopChoice = () => {
		console.log("top choice clicked");
		setState(WRONG_CHOICE);
	};
	const handleBottomChoice = () => {
		console.log("bottom choice clicked");
		setState(RIGHT_CHOICE);
	};

	return (
		<Fade fadeProp={fade} durration="700ms">
			<SlideContiner
				onClick={handleClick}
				backgroundColor={
					state === WRONG_CHOICE || state === SINGLE_CHOICE ? "black" : "white"
				}
			>
				<BackgroundImage
					image={shareChips}
					animation={
						state === WRONG_CHOICE
							? toLowOpacityInvertAnimation
							: state === RIGHT_CHOICE
							? toLowOpacityAnimation
							: state === SINGLE_CHOICE
							? reverseInvertAnimation
							: ""
					}
					durration="1s"
				/>
				{state === INITIAL ? (
					<Fade fadeProp="fadein" durration="1400ms">
						<BottomTextBox opacity={0.97}>
							You haven't eaten in quite some time, so you'd rather not.
						</BottomTextBox>
					</Fade>
				) : state === SECOND_TEXTBOX ? (
					<Fade fadeProp="fadein" durration="700ms">
						<BottomTextBox opacity={0.97}>
							How do you choose to respond to the man?{" "}
						</BottomTextBox>
					</Fade>
				) : state === MULTIPLE_CHOICE ? (
					<Fade fadeProp="fadein" durration="2s">
						<HeaderText>Choose one of the following options:</HeaderText>
						<TopBox onClick={handleTopChoice}>
							<ChoiceText>“Get lost, old man.”</ChoiceText>
						</TopBox>
						<BottomBox onClick={handleBottomChoice}>
							<ChoiceText>Be pleasant </ChoiceText>
						</BottomBox>
					</Fade>
				) : state === SINGLE_CHOICE ? (
					<BottomBox onClick={handleBottomChoice}>
						<ChoiceText>Be pleasant</ChoiceText>
					</BottomBox>
				) : state === WRONG_CHOICE ? (
					<Fade fadeProp="fadein" durration="1s">
						<Border borderColor="white" textColor={"white"}>
							It's unwise to go about antagonizing people, especially strangers.
							In some places, people may excuse your behavior. But generally,
							exhibiting disrespectful attitudes in unpredictable environments
							may not yield the safest results. Be careful.
						</Border>
					</Fade>
				) : state === RIGHT_CHOICE ? (
					<Fade fadeProp="fadein" durration="1s">
						<Border borderColor="#21622B" textColor={"#21622B"}>
							Well done! It doesn't actually matter whether you give him your
							chips or not. But if you respond in a respectful manner, you may
							find a lot of good comes from just the most basic levels of
							respect.
						</Border>
					</Fade>
				) : (
					<></>
				)}
			</SlideContiner>
		</Fade>
	);
};
export default ShareChips;
