import { keyframes } from "styled-components";
const reverseInvertAnimation = keyframes`
from {
	filter: invert(1) ;opacity:0.1 }
to {
	filter: invert(0);
	opacity:1
}`;

export default reverseInvertAnimation;
