import { keyframes } from "styled-components";
const toLowOpacityAnimation = keyframes`
from {
	opacity: 1
}
to {
	opacity: 0.04
}
`;
export default toLowOpacityAnimation;
