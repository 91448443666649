import cold from "../../../img/cold.png";
import queueNextSlide from "../../Util/queueNextSlide";
import { useState } from "react";
import BackgroundImage from "../../StyledComponents/BackgroundImage";
import Fade from "../../Transitions/Fade";
import SlideContainer from "../../StyledComponents/SlideContainer";
import BottomTextBox from "../../StyledComponents/BottomTextBox";

function Cold({ handleSlide, slide }) {
	const [fade, setFade] = useState("fadein");
	const [secondTextBox, setSecondTextBox] = useState(false);
	const delay = 700;
	const clickHandler = () => {
		if (!secondTextBox) {
			setSecondTextBox(true);
		} else if (secondTextBox) {
			queueNextSlide(handleSlide, slide, "Christmas");
			setFade("fadeout");
		}
	};
	return (
		<Fade fadeProp={fade} durration="700ms">
			<SlideContainer onClick={clickHandler}>
				<BackgroundImage image={cold} />

				<Fade fadeProp="fadein" durration="1400ms">
					{secondTextBox ? (
						<BottomTextBox opacity={0.98}>
							As you wait for the bus heading to the Tenderloin, you see happy
							families getting in the Christmas spirit.
						</BottomTextBox>
					) : (
						<BottomTextBox opacity={0.98}>It’s violently cold!</BottomTextBox>
					)}
				</Fade>
			</SlideContainer>
		</Fade>
	);
}
export default Cold;
