import train4 from "../../../img/train4.png";
import queueNextSlide from "../../Util/queueNextSlide";
import { useState } from "react";
import BackgroundImage from "../../StyledComponents/BackgroundImage";
import Fade from "../../Transitions/Fade";
import SlideContainer from "../../StyledComponents/SlideContainer";
import BottomTextBox from "../../StyledComponents/BottomTextBox";

function Train4({ handleSlide, slide }) {
	const [fade, setFade] = useState("fadein");
	const [state, setState] = useState(1);
	const delay = 700;
	const clickHandler = () => {
		if (state === 1) {
			setState(2);
		} else if (state === 2) {
			setState(3);
		} else {
			queueNextSlide(handleSlide, slide, "NineAM");
			setFade("fadeout");
		}
	};
	return (
		<Fade fadeProp={fade} durration="700ms">
			<SlideContainer onClick={clickHandler}>
				<BackgroundImage image={train4} />

				<Fade fadeProp="fadein" durration="1400ms">
					{state === 1 ? (
						<BottomTextBox opacity={0.98}>
							You decide to give him some of your chips.
						</BottomTextBox>
					) : state === 2 ? (
						<BottomTextBox opacity={0.98}>
							The old man is grateful! He realizes he’s never seen you on the
							train and assumes you’re newly homeless.
						</BottomTextBox>
					) : (
						<BottomTextBox opacity={0.98}>
							He lets you know that if you are homeless, you can go to the
							“Tenderloin” and get a referral to a shelter.
						</BottomTextBox>
					)}
				</Fade>
			</SlideContainer>
		</Fade>
	);
}
export default Train4;
