import "./Train2.css";
import Fade from "../../Transitions/Fade";
import queueNextSlide from "../../Util/queueNextSlide";
import BottomTextBox from "../../StyledComponents/BottomTextBox";
import SlideContiner from "../../StyledComponents/SlideContainer";
import { useState } from "react";
import train2 from "../../../img/train2.png";
import styled, { keyframes } from "styled-components";
import toLowOpacityAnimation from "../../Transitions/toLowOpacityAnimation";
import toLowOpacityInvertAnimation from "../../Transitions/toLowOpacityInvertAnimation";
import reverseInvertAnimation from "../../Transitions/reverseInvertAnimation";
import BackgroundImage from "../../StyledComponents/BackgroundImage";
import Border from "../../StyledComponents/Border";
const HeaderText = styled.div`
	position: absolute;
	width: 655px;
	height: 64px;
	left: 1257px;
	top: 160px;

	font-family: "adobe_naskhmedium";
	font-style: normal;
	font-weight: 500;
	font-size: 44px;
	line-height: 34px;
	/* or 78% */

	text-align: center;

	color: #000000;
`;
const TopBox = styled.button`
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: absolute;
	width: 461px;
	height: 116px;
	left: 1353px;
	top: 255px;
	cursor: pointer;
	border: 6.81px solid #5c5c5c;
	background-color: white;
`;
const BottomBox = styled.button`
	box-sizing: border-box;

	position: absolute;
	width: 461px;
	height: 116px;
	left: 1353px;
	top: 387px;

	border: 6.81px solid #5c5c5c;
	display: flex;
	flex-direction: column;
	justify-content: center;
	cursor: pointer;
	background-color: white;
`;

const ChoiceText = styled.div`
	/* position: absolute; */
	width: 381px;
	height: 59px;
	/* left: 1393px; */
	/* top: 406px; */

	margin: 0 auto;
	font-family: "adobe_arabicregular";
	font-style: normal;
	font-weight: 400;
	font-size: 54px;
	line-height: 59px;

	color: black;
`;

const gradiantAnimation = keyframes`
 0%{ 
background: left;
}
 100% {background:right;
}
`;

function Train2({ handleSlide, slide }) {
	const [fadeout, setFadeout] = useState(false);
	const [textBox, setTextBox] = useState(false);
	const [multipleChoice, setMultipleChoice] = useState(false);
	const [BorderBoxWrongChoice, setBorderBoxWrongChoice] = useState(false);
	const [BorderBoxRightChoice, setBorderBoxRightChoice] = useState(false);
	const [singleChoice, setSingleChoice] = useState(false);
	const [topChoiceSelected, setTopChoiceSelected] = useState(false);
	const [bottomChoiceSelected, setBottomChoiceSelected] = useState(false);
	const handleTopChoice = () => {
		setMultipleChoice(false);
		setBorderBoxWrongChoice(true);
		setTopChoiceSelected(true);
	};

	const handleBottomChoice = () => {
		setMultipleChoice(false);
		setSingleChoice(false);
		setBorderBoxRightChoice(true);
		setBottomChoiceSelected(true);
	};

	const delay = 700;
	const clickHandler = () => {
		if (textBox === false) {
			setTextBox("first textbox");
		} else if (textBox === "first textbox") {
			setTextBox("second textbox");
		} else if (textBox === "second textbox") {
			setTextBox("off");
			setMultipleChoice(true);
		} else if (multipleChoice === true) {
			console.log("click a choice");
		} else if (bottomChoiceSelected === true) {
			console.log("bottom selected");
			queueNextSlide(handleSlide, slide, "Chips", delay);
			setFadeout(true);
		} else if (topChoiceSelected === true && BorderBoxWrongChoice === true) {
			console.log("wrong choice");
			setBorderBoxWrongChoice(false);
			setSingleChoice(true);
		} else if (singleChoice === true) {
			console.log("click a choice to continue");
		}
	};
	return (
		<Fade
			className="Fade"
			fadeProp={fadeout ? "fadeout" : "fadein"}
			durration="700ms"
		>
			<SlideContiner
				className="Train2"
				backgroundColor={
					BorderBoxWrongChoice || singleChoice ? "black" : "white"
				}
				onClick={clickHandler}
			>
				<BackgroundImage
					image={train2}
					className="TrainImg"
					animation={
						BorderBoxWrongChoice
							? toLowOpacityInvertAnimation
							: BorderBoxRightChoice
							? toLowOpacityAnimation
							: singleChoice
							? reverseInvertAnimation
							: ""
					}
					durration="1s"
				/>
				{textBox === "first textbox" ? (
					<Fade
						className="BottomTextBoxFade"
						fadeProp="fadein"
						durration="300ms"
					>
						<BottomTextBox opacity={0.96}>You've lost everything</BottomTextBox>
					</Fade>
				) : textBox === "second textbox" ? (
					<Fade fadeProp="fadein" durration="300ms" zIndex="100">
						<BottomTextBox opacity={0.96}>
							How will you deal with this
						</BottomTextBox>
					</Fade>
				) : textBox === "off" ? (
					<></>
				) : (
					<></>
				)}
				{multipleChoice ? (
					<Fade fadeProp="fadein" durration="2s">
						<HeaderText>Choose one of the following options:</HeaderText>
						<TopBox onClick={handleTopChoice}>
							<ChoiceText>Become discouraged</ChoiceText>
						</TopBox>
						<BottomBox onClick={handleBottomChoice}>
							<ChoiceText>Strengthen your resolve</ChoiceText>
						</BottomBox>
					</Fade>
				) : singleChoice ? (
					<BottomBox onClick={handleBottomChoice}>
						<ChoiceText>Strengthen your resolve</ChoiceText>
					</BottomBox>
				) : (
					<></>
				)}
				{BorderBoxWrongChoice ? (
					<Fade fadeProp="fadein" durration="1s">
						<Border borderColor="white" textColor={"white"}>
							Setting your mind on misery and sorrow accomplishes nothing. The
							only way to survive at your lowest point is to face your own
							hardships.
						</Border>
					</Fade>
				) : BorderBoxRightChoice ? (
					<Fade fadeProp="fadein" durration="1s">
						<Border borderColor="#21622B" textColor="#21622B">
							Right you are! Learn from your mistakes, accept them, and move
							forward. In life, there will always be situations/people who aim
							to discourage you. If you have this at the front of your mind, you
							will be prepared. So learn from your mistakes and press on with an
							iron will!
						</Border>
					</Fade>
				) : (
					<></>
				)}
			</SlideContiner>
		</Fade>
	);
}
export default Train2;
