import styled from "styled-components";

const TextBox = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: column;
	position: absolute;
	background-color: white;
	min-width: 100%;
	height: 126px;
	bottom: 0;
	/* opacity: ${({ opacity }) => (opacity ? opacity : 1)}; */
	opacity: 0.96;
`;
const fontSize = ({ fontSize }) => (fontSize ? fontSize : "65px");
const Text = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: column;
	position: relative;
	width: 1700px;
	height: 105px;
	margin: 0 auto;
	top: 50%;
	transform: translateY(-50%);

	/* font-family: "Adobe Naskh"; */
	/* why does using installed system fonts make sizes different */
	font-family: "adobe_naskhmedium";
	font-style: normal;
	font-weight: 500;
	font-size: ${fontSize};
	line-height: 51px;
	/* or 79% */

	text-align: center;

	color: #21622b;
`;

function BottomTextBox({ children, opacity, fontSize }) {
	return (
		<TextBox>
			<Text fontSize={fontSize}>{children}</Text>
		</TextBox>
	);
}

export default BottomTextBox;
