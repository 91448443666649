import SlideContiner from "../../StyledComponents/SlideContainer";
import Fade from "../../Transitions/Fade";
import queueNextSlide from "../../Util/queueNextSlide";
import styled from "styled-components";
import React, { useState } from "react";
import Background from "../../StyledComponents/Background";
const TextBox = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	text-align: center;
	justify-content: center;
	width: 100%;
	height: auto;
	background-color: black;
`;

const Text = styled.div`
	/* On the street, day & night : */

	position: relative;
	/* width: 574px; */
	/* height: 72px; */
	/* left: 675px; */
	/* top: 477px; */

	font-family: "Adobe Naskh";
	font-style: normal;
	font-weight: 500;
	font-size: 43px;
	line-height: 82px;
	/* or 191% */
	display: flex;
	flex-direction: column;
	text-align: center;
	justify-content: center;
	text-align: center;
	letter-spacing: 0.025em;
	/* text-decoration-line: underline; */

	color: #ffffff;

	/* a choose-your-own-adventure manual */

	/* position: relative; */
	width: auto;
	height: 45px;

	font-family: "adobe_naskhmedium";
	font-style: normal;
	font-weight: 500;
	font-size: 55px;
	line-height: 34px;
	/* or 79% */

	text-align: center;

	color: #ffffff;
`;

function Title({ handleSlide, slide }) {
	const delay = 700;
	const [fade, setFade] = useState("fadein");
	const handleClick = () => {
		setFade("fadeout");
		queueNextSlide(handleSlide, slide, "Title", delay);
	};
	// Look into why function invocation renders component without clicking, example bellow
	// <div className="Title" onClick={queueNextSlide(handleSlide, slide, "Date", delay)}></div>
	// Look into why arrow function is needed, example
	// <div className="Title" onClick={ ()=> queueNextSlide(handleSlide, slide, "Date", delay)}></div>
	return (
		<Background backgroundColor={fade === "fadein" ? "black" : "white"}>
			<Fade className="Fade" fadeProp={fade} durration={delay + "ms"}>
				<SlideContiner
					className="Title"
					backgroundColor="black"
					onClick={handleClick}
				>
					<TextBox>
						<Text>Fin</Text>
					</TextBox>
				</SlideContiner>
			</Fade>
		</Background>
	);
}

export default Title;
