import styled from "styled-components";

const animation = ({ animation }) => (animation ? animation : "");
const durration = ({ durration }) => (durration ? durration : "");
const BackgroundImage = styled.div`
	position: absolute;
	background-color: white;
	width: 1920px;
	height: 1080px;

	/* background-image: linear-gradient(
			-115deg,
			rgba(255, 255, 255, 1) 0%,
			rgba(255, 255, 255, 1) 12%,
			rgba(255, 255, 255, 0) 50%
		), */
	background-image: url(${({ image }) => image});
	animation: ${animation} ${durration};
	${animation && durration ? "animation-fill-mode:forwards" : ""}
`;
export default BackgroundImage;
