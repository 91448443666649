import tenderloin2 from "../../../img/tenderloin2.png";
import queueNextSlide from "../../Util/queueNextSlide";
import { useState } from "react";
import BackgroundImage from "../../StyledComponents/BackgroundImage";
import Fade from "../../Transitions/Fade";
import SlideContainer from "../../StyledComponents/SlideContainer";
import BottomTextBox from "../../StyledComponents/BottomTextBox";

function Tenderloin2({ handleSlide, slide }) {
	const [fade, setFade] = useState("fadein");
	const [state, setState] = useState(1);
	const delay = 700;
	const clickHandler = () => {
		if (state === 1) {
			setState(2);
		} else if (state === 2) {
			queueNextSlide(handleSlide, slide, "Tenderloin3");
			setFade("fadeout");
		}
	};
	return (
		<Fade fadeProp={fade} durration="700ms">
			<SlideContainer onClick={clickHandler}>
				<BackgroundImage image={tenderloin2} />
				<Fade fadeProp="fadein" durration="1400ms">
					{state === 1 ? (
						<BottomTextBox opacity={0.98}>
							Here you receive food, a shower, and a referral to the closest
							shelter.
						</BottomTextBox>
					) : (
						<BottomTextBox opacity={0.98}>
							Identification cards are not required in order to be referred.
						</BottomTextBox>
					)}
				</Fade>
			</SlideContainer>
		</Fade>
	);
}
export default Tenderloin2;
