import tenderloin1 from "../../../img/tenderloin1.png";
import queueNextSlide from "../../Util/queueNextSlide";
import { useState } from "react";
import BackgroundImage from "../../StyledComponents/BackgroundImage";
import Fade from "../../Transitions/Fade";
import SlideContainer from "../../StyledComponents/SlideContainer";
import BottomTextBox from "../../StyledComponents/BottomTextBox";

function Tenderloin({ handleSlide, slide }) {
	const [fade, setFade] = useState("fadein");
	const delay = 700;
	const clickHandler = () => {
		queueNextSlide(handleSlide, slide, "Tenderloin2");
		setFade("fadeout");
	};
	return (
		<Fade fadeProp={fade} durration="700ms">
			<SlideContainer onClick={clickHandler}>
				<BackgroundImage image={tenderloin1} />
				<Fade fadeProp="fadein" durration="1200ms">
					<BottomTextBox opacity={0.98}>
						You arrive at the Tenderloin Linkage Center.
					</BottomTextBox>
				</Fade>
			</SlideContainer>
		</Fade>
	);
}
export default Tenderloin;
