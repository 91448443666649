import styled from "styled-components";
const Text = styled.div`
	height: auto;
	width: 88%;
	position: relative;
	display: flex;
	justify-content: center;
	flex-direction: column;
	padding-top: 5%;
	padding-bottom: 5%;
	margin: 0 auto;
	text-align: center;
	font-family: "Adobe Arabic";
	font-style: normal;
	font-weight: 400;
	font-size: 62px;
	line-height: 81px;
	text-align: center;
	color: ${({ textColor }) => textColor};
`;
// console.log(children);
const BorderBox = styled.div`
	box-sizing: border-box;
	position: relative;
	display: flex;
	justify-content: center;
	flex-direction: column;
	margin: 0 auto;
	width: 57%;
	height: auto;
	border: 7px solid ${({ borderColor }) => borderColor};
`;

const Border = ({ children, borderColor, textColor }) => {
	return (
		<BorderBox borderColor={borderColor}>
			<Text textColor={textColor}>{children}</Text>
		</BorderBox>
	);
};

export default Border;
