import { useState } from "react";
import queueNextSlide from "../../Util/queueNextSlide";
import SlideContiner from "../../StyledComponents/SlideContainer";
import BackgroundImage from "../../StyledComponents/BackgroundImage";
import Fade from "../../Transitions/Fade";
import Border from "../../StyledComponents/Border";
import toLowOpacityAnimation from "../../Transitions/toLowOpacityAnimation";
import toLowOpacityInvertAnimation from "../../Transitions/toLowOpacityInvertAnimation";
import reverseInvertAnimation from "../../Transitions/reverseInvertAnimation";
import christmas from "../../../img/christmas.png";
import styled from "styled-components";
const HeaderText = styled.div`
	position: absolute;
	width: 655px;
	height: 64px;
	left: 225px;
	top: 578px;

	font-family: "adobe_naskhmedium";
	font-style: normal;
	font-weight: 500;
	font-size: 65px;
	line-height: 34px;
	/* or 78% */

	text-align: center;
	color: white;
`;
const TopBox = styled.button`
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: absolute;
	width: 548px;
	height: 94px;
	left: 277px;
	top: 661px;
	cursor: pointer;
	border: 6.81px solid #5c5c5c;
	background-color: white;
`;
const BottomBox = styled.button`
	box-sizing: border-box;

	position: absolute;
	width: 548px;
	height: 94px;
	left: 277px;
	top: 834px;

	border: 6.81px solid #5c5c5c;
	display: flex;
	flex-direction: column;
	justify-content: center;
	cursor: pointer;
	background-color: white;
`;

const ChoiceText = styled.div`
	/* position: absolute; */
	width: 400px;
	height: 59px;
	/* left: 1393px; */
	/* top: 406px; */
	margin: 0 auto;
	font-family: "adobe_arabicregular";
	font-style: normal;
	font-weight: 400;
	font-size: 54px;
	line-height: 59px;

	color: black;
`;

const Christmas = ({ handleSlide, slide }) => {
	const MULTIPLE_CHOICE = "multiple choice";
	const SINGLE_CHOICE = "single choice";
	const WRONG_CHOICE = "wrong choice";
	const RIGHT_CHOICE = "right choice";
	const delay = 700;

	const [fade, setFade] = useState("fadein");
	const [state, setState] = useState(MULTIPLE_CHOICE);

	const handleClick = () => {
		if (state === MULTIPLE_CHOICE) {
			console.log("click a choice");
		} else if (state === WRONG_CHOICE) {
			setState(SINGLE_CHOICE);
		} else if (state === RIGHT_CHOICE) {
			queueNextSlide(handleSlide, slide, "Train5", delay);
			setFade("fadeout");
		}
	};
	const handleTopChoice = () => {
		console.log("top choice clicked");
		setState(RIGHT_CHOICE);
	};
	const handleBottomChoice = () => {
		console.log("bottom choice clicked");
		setState(WRONG_CHOICE);
	};

	return (
		<Fade fadeProp={fade} durration="700ms">
			<SlideContiner
				onClick={handleClick}
				backgroundColor={
					state === WRONG_CHOICE || state === SINGLE_CHOICE ? "black" : "white"
				}
			>
				<BackgroundImage
					image={christmas}
					animation={
						state === WRONG_CHOICE
							? toLowOpacityInvertAnimation
							: state === RIGHT_CHOICE
							? toLowOpacityAnimation
							: state === SINGLE_CHOICE
							? reverseInvertAnimation
							: ""
					}
					durration="1s"
				/>
				{state === MULTIPLE_CHOICE ? (
					<Fade fadeProp="fadein" durration="2s">
						<HeaderText>What's on your mind:</HeaderText>
						<TopBox onClick={handleTopChoice}>
							<ChoiceText>Focus on the Tenderloin</ChoiceText>
						</TopBox>
						<BottomBox onClick={handleBottomChoice}>
							<ChoiceText>Reflect on past mistakes </ChoiceText>
						</BottomBox>
					</Fade>
				) : state === SINGLE_CHOICE ? (
					<TopBox onClick={handleTopChoice}>
						<ChoiceText>Focus on the Tenderloin</ChoiceText>
					</TopBox>
				) : state === WRONG_CHOICE ? (
					<Fade fadeProp="fadein" durration="1s">
						<Border borderColor="white" textColor={"white"}>
							You know this the wrong approach.
						</Border>
					</Fade>
				) : state === RIGHT_CHOICE ? (
					<Fade fadeProp="fadein" durration="1s">
						<Border borderColor="#21622B" textColor={"#21622B"}>
							Well done friend! Focus.
						</Border>
					</Fade>
				) : (
					<></>
				)}
			</SlideContiner>
		</Fade>
	);
};
export default Christmas;
