import "./Train.css";
import train from "../../../img/train.png";
import { useState } from "react";
import Fade from "../../Transitions/Fade";
import BottomTextBox from "../../StyledComponents/BottomTextBox";
import queueNextSlide from "../../Util/queueNextSlide";
import SlideContiner from "../../StyledComponents/SlideContainer";
import styled from "styled-components";
import BackgroundImage from "../../StyledComponents/BackgroundImage";
const ImageBackground = styled.img`
	position: relative;
	width: 1920px;
	height: 1080px;
	background-color: crimson;
	background-image: url(train);
`;

function Train({ handleSlide, slide }) {
	const [textBox, setTextBox] = useState(false);
	const [fadeOut, SetFadeOut] = useState(false);
	const delay = 700;
	function clickHandler() {
		if (textBox === false) {
			setTextBox(true);
		} else {
			queueNextSlide(handleSlide, slide, "Train2", delay);
			SetFadeOut(true);
		}
	}
	return (
		<Fade fadeProp={fadeOut ? "fadeout" : "fadein"} durration="700ms">
			<SlideContiner className="Train" onClick={clickHandler}>
				<BackgroundImage image={train} />
				{textBox ? (
					<Fade fadeProp="fadein" durration="300ms">
						<BottomTextBox opacity={0.96}>
							You find yourself on a train ...
						</BottomTextBox>
					</Fade>
				) : (
					<></>
				)}
			</SlideContiner>
		</Fade>
	);
}

export default Train;
