import SlideContiner from "../../StyledComponents/SlideContainer";
import Fade from "../../Transitions/Fade";
import queueNextSlide from "../../Util/queueNextSlide";
import styled from "styled-components";
import React, { useState } from "react";
import Background from "../../StyledComponents/Background";
const TextBox = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	text-align: center;
	justify-content: center;
	width: 100%;
	height: auto;
	background-color: black;
`;

const Underline = styled.div`
	position: absolute;
	top: 64px;
	left: 767px;
	width: 363px;
	height: 2px;
	background-color: white;
`;
const Text = styled.div`
	position: relative;
	/* font-family: "Adobe Naskh"; */
	font-family: "adobe_naskhmedium";
	font-style: normal;
	font-weight: 500;
	font-size: 43px;
	line-height: 82px;
	letter-spacing: 0.025em;
	/* or 191% */
	display: flex;
	flex-direction: column;
	text-align: center;
	justify-content: center;
	text-align: center;
	/* text-decoration-line: underline; */
	/* text-underline-offset: 40px; */
	color: white;
`;

const Text2 = styled.div`
	position: relative;
	font-family: "adobe_naskhmedium";
	font-style: normal;
	font-weight: 500;
	font-size: 43px;
	line-height: 2px;
	letter-spacing: 0.025em;
	/* or 191% */
	display: flex;
	flex-direction: column;
	text-align: center;
	justify-content: center;
	text-align: center;
	/* text-decoration-line: underline; */
	/* text-underline-offset: 20px; */
	color: #ffffff;
`;
function Title({ handleSlide, slide }) {
	const delay = 700;
	const [fade, setFade] = useState("fadein");
	const handleClick = () => {
		setFade("fadeout");
		queueNextSlide(handleSlide, slide, "Date", delay);
	};
	// Look into why function invocation renders component without clicking, example bellow
	// <div className="Title" onClick={queueNextSlide(handleSlide, slide, "Date", delay)}></div>
	// Look into why arrow function is needed, example
	// <div className="Title" onClick={ ()=> queueNextSlide(handleSlide, slide, "Date", delay)}></div>
	return (
		<Background backgroundColor={fade === "fadein" ? "black" : "white"}>
			<Fade className="Fade" fadeProp={fade} durration={delay + "ms"}>
				<SlideContiner
					className="Title"
					backgroundColor="black"
					onClick={handleClick}
				>
					<TextBox>
						<Text>On the street, day & night :</Text>
						<Underline />
						<Text2>a choose-your-own-adventure manual</Text2>
					</TextBox>
				</SlideContiner>
			</Fade>
		</Background>
	);
}

export default Title;
