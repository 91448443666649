import styled from "styled-components";

const Background = styled.div`
	position: absolute;
	width: 1920px;
	height: 1080px;
	background-color: ${({ backgroundColor }) => backgroundColor};
`;

export default Background;
