import styled from "styled-components";

const backgroundColor = ({ backgroundColor }) =>
	backgroundColor ? backgroundColor : "white";

const SlideContiner = styled.div`
	position: absolute;
	width: 1920px;
	height: 1080px;
	background-color: ${backgroundColor};
	display: flex;
	justify-content: center;
	flex-direction: column;
	/* text-align: center; */
	/* margin: 0 auto; */
`;

export default SlideContiner;
