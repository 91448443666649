import styled from "styled-components";
import "@fontsource/wire-one";
const Hour = styled.div`
	position: absolute;
	width: 947px;
	height: 73px;
	left: 487px;
	top: 504px;

	font-family: "Wire One";
	font-style: normal;
	font-weight: 400;
	font-size: 331px;
	line-height: 34px;
	/* or 10% */

	text-align: center;

	color: #21622b;
`;

// function Hour({ handleSlide, slide }) {
// 	const [fadout, setFadeout] = useState(false);
// 	const delay = 600;
// 	const clickHandler = () => {
// 		queueNextSlide(handleSlide, slide, "Train", delay);
// 	};
// 	return (
// 		<SlideContiner className="Morning" onClick={clickHandler}>
// 			<Fade>
// 				<div className="MorningText">5 AM</div>
// 			</Fade>
// 		</SlideContiner>
// 	);
// }

export default Hour;
