import train3 from "../../../img/train3.png";
import queueNextSlide from "../../Util/queueNextSlide";
import { useState } from "react";
import BackgroundImage from "../../StyledComponents/BackgroundImage";
import Fade from "../../Transitions/Fade";
import SlideContainer from "../../StyledComponents/SlideContainer";
import BottomTextBox from "../../StyledComponents/BottomTextBox";

function Train3({ handleSlide, slide }) {
	const [fade, setFade] = useState("fadein");
	const delay = 700;
	const clickHandler = () => {
		queueNextSlide(handleSlide, slide, "ShareChips");
		setFade("fadeout");
	};
	return (
		<Fade fadeProp={fade} durration="700ms">
			<SlideContainer onClick={clickHandler}>
				<BackgroundImage image={train3} />
				<Fade fadeProp="fadein" durration="1400ms">
					<BottomTextBox opacity={0.98}>
						A homeless man sleeping across from you hears this, wakes up, and
						asks if you wouldn’t mind sharing some.
					</BottomTextBox>
				</Fade>
			</SlideContainer>
		</Fade>
	);
}
export default Train3;
