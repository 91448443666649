import styled, { keyframes } from "styled-components";

const durration = ({ durration }) => durration;
const zIndex = ({ zIndex }) => (zIndex ? zIndex : "auto");
const fadeProp = ({ fadeProp }) =>
	fadeProp === "fadein" ? fadeIn : fadeProp === "fadeout" ? fadeOut : "";
const fadeIn = keyframes`
from {
    opacity:0
}
to {
    opacity:1
}
`;

const fadeOut = keyframes`
from {
    opacity:1
}
to {
    opacity:0
}
`;
const Fade = styled.div`
	/* background-color: lime; */
	/* position: absolute; */
	/* height: 444px; */
	width: 1920px;
	/* findout out why giving a height breaks the Fade container on top of an image.
	The Fade comp displays bellow image */
	animation: ${fadeProp} ${durration} forwards;
	/* z-index: ${zIndex}; */
`;

export default Fade;
