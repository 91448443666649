import { keyframes } from "styled-components";
const toLowOpacityInvertAnimation = keyframes`
from {
	filter: invert(0) ;opacity:1 }
to {
	filter: invert(1);
	opacity:0.1
}`;

export default toLowOpacityInvertAnimation;
