/* eslint-disable default-case */
import "./App.css";
import React, { useEffect, useLayoutEffect } from "react";
import { useState } from "react";
import Title from "./Components/Slides/Title/Title";
import Date from "./Components/Slides/Date/Date";
import Train from "./Components/Slides/Train/Train";
import FiveAM from "./Components/Slides/Hour/FiveAM";
import Train2 from "./Components/Slides/Train/Train2";
import Chips from "./Components/Slides/Chips/Chips";
import Train3 from "./Components/Slides/Train/Train3";
import Train4 from "./Components/Slides/Train/Train4";
import ShareChips from "./Components/Slides/Chips/ShareChips";
import ElevenAM from "./Components/Slides/Hour/ElevenAM";
import NineAM from "./Components/Slides/Hour/NineAM";
import Cold from "./Components/Slides/Cold/Cold";
import Christmas from "./Components/Slides/Christmas/Christmas";
import Train5 from "./Components/Slides/Train/Train5";
import Tenderloin from "./Components/Slides/Tenderloin/Tenderloin";
import Tenderloin2 from "./Components/Slides/Tenderloin/Tenderloin2";
import Tenderloin3 from "./Components/Slides/Tenderloin/Tenderloin3";
import Fin from "./Components/Slides/Fin/Fin";
const slidesObj = {
	Title: Title,
	Date: Date,
	Train: Train,
	Train2: Train2,
	FiveAM: FiveAM,
	Chips: Chips,
	Train3: Train3,
	ShareChips: ShareChips,
	ElevenAM: ElevenAM,
	NineAM: NineAM,
	Train4: Train4,
	Cold: Cold,
	Christmas: Christmas,
	Train5: Train5,
	Tenderloin: Tenderloin,
	Tenderloin2: Tenderloin2,
	Tenderloin3: Tenderloin3,
	Fin: Fin,
};
function App() {
	const [slide, setSlide] = useState("Title");
	let CurrSlide = slidesObj[`${slide}`];

	function handleSlide(slide) {
		setSlide(slide);
		// console.log(slide, " slide");
	}

	return (
		<div className="App">
			<div className="SlideApp">
				{/* <Fin /> */}
				<CurrSlide slide={slide} handleSlide={handleSlide} />
			</div>
		</div>
	);
}

export default App;
