import { useState } from "react";
import Hour from "../../StyledComponents/Hour";
import SlideContiner from "../../StyledComponents/SlideContainer";
import queueNextSlide from "../../Util/queueNextSlide";
import Fade from "../../Transitions/Fade";
function ElevenAM({ handleSlide, slide }) {
	const [fadeout, setFadeOut] = useState(false);
	const delay = 700;

	const onClick = () => {
		queueNextSlide(handleSlide, slide, "Tenderloin", delay);
		setFadeOut(true);
	};
	return (
		<Fade
			fadeProp={fadeout ? "fadeout" : "fadein"}
			durration={fadeout ? delay + 100 + "ms" : "1100ms"}
		>
			<SlideContiner className="5AM" onClick={onClick}>
				<Hour>11AM</Hour>
			</SlideContiner>
		</Fade>
	);
}
export default ElevenAM;
