import "./Date.css";
import "@fontsource/wire-one";
import Fade from "../../Transitions/Fade";
import queueNextSlide from "../../Util/queueNextSlide";
import { useState } from "react";
import SlideContiner from "../../StyledComponents/SlideContainer";
function Date({ handleSlide, slide }) {
	const [fadeout, setFadeOut] = useState(false);
	const delay = 700;
	const clickHandler = () => {
		queueNextSlide(handleSlide, slide, "FiveAM", delay);
		setFadeOut(true);
	};
	return (
		<Fade
			fadeProp={fadeout ? "fadeout" : ""}
			durration={fadeout ? delay + "ms" : ""}
		>
			<SlideContiner className="Date" onClick={clickHandler}>
				<Fade fadeProp="fadein" durration="900ms">
					<h1 className="Header">Decermber 21</h1>
				</Fade>
				<Fade fadeProp="fadein" durration="1800ms">
					<h1 className="SubHeader">San Francisco, California</h1>
				</Fade>
			</SlideContiner>
		</Fade>
	);
}

export default Date;
