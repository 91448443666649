import tenderloin3 from "../../../img/tenderloin3.jpg";
import queueNextSlide from "../../Util/queueNextSlide";
import { useState } from "react";
import BackgroundImage from "../../StyledComponents/BackgroundImage";
import Fade from "../../Transitions/Fade";
import SlideContainer from "../../StyledComponents/SlideContainer";
import BottomTextBox from "../../StyledComponents/BottomTextBox";

function Tenderloin3({ handleSlide, slide }) {
	const [fade, setFade] = useState("fadein");
	const [state, setState] = useState(1);
	const delay = 700;
	const clickHandler = () => {
		if (state === 1) {
			setState(2);
		} else if (state === 2) {
			setState(3);
		} else if (state === 3) {
			setState(4);
		} else {
			queueNextSlide(handleSlide, slide, "Fin");
			setFade("fadeout");
		}
	};
	return (
		<Fade fadeProp={fade} durration="700ms">
			<SlideContainer onClick={clickHandler}>
				<BackgroundImage image={tenderloin3} />
				<Fade fadeProp="fadein" durration="1400ms">
					{state === 1 ? (
						<BottomTextBox>
							Congratulations! You have been referred to MSC Homeless Shelter, a
							20-minute walk from the Tenderloin.
						</BottomTextBox>
					) : state === 2 ? (
						<BottomTextBox>
							Here you'll find all the resources needed to get back on your
							feet. You’ll be assigned a bed and locker, and will be required to
							clean after yourself.
						</BottomTextBox>
					) : state === 3 ? (
						<BottomTextBox>
							Counselors will help you meet your goals in acquiring a job and
							sustainable housing. However, you must be consistent and meet each
							and every goal you set for yourself.
						</BottomTextBox>
					) : (
						<BottomTextBox>
							As long as you maintain the right mindset and remain focused,
							you’ll transform your life.
						</BottomTextBox>
					)}
				</Fade>
			</SlideContainer>
		</Fade>
	);
}
export default Tenderloin3;
